import React, { useRef } from 'react';
import './TestimonialVideo.css';
import { HashLink } from 'react-router-hash-link';

const TestimonialVideo = () => {
  const videoRef = useRef(null);

  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleContactUsClick = () => {
    window.location.href = 'https://3ssd63ys2hc.typeform.com/to/qDYn1xHC';
  };

  return (
    <div className="testimonial-container">
      <div className="testimonial-video-container" onClick={handleVideoPlay}>
        <video ref={videoRef} controls className="testimonial-video">
          <source src={require('../../assets/videos/ansh-testimonial.mp4')} type="video/mp4" />
        </video>
      </div>
      <div className="testimonial-content glassmorphic-card">
        <h3>Hear What Our Student <span className='highlight'>Ansh Had to Say: </span></h3>
        <p><em>"Learnfinity has been life-changing. Anwar’s support helped me master key tech skills and build confidence. If you want to break into tech, this is the program for you."</em></p>
        <div className="cta-container">
          <button className="btn" id='alternate-button' onClick={() => handleContactUsClick()}>Get In Touch</button>
        </div>
      </div>
    </div>
  );
}

export default TestimonialVideo;
